"use client";
import useIsPreviewingBuilder from "@/hooks/useIsPreviewingBuilder";
import useStoreKeys from "@/hooks/useStoreKeys";
import { Builder } from "@builder.io/react";
import React, { FC, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

interface Props {
	region: string;
	portalId: string;
	formId: string;
}

const ScriptLoader: FC<Props> = (props) => {
	const { region, portalId, formId } = props;

	const { isEditingBuilder } = useIsPreviewingBuilder();

	const [id, setId] = useState<string>();

	const target = useRef<HTMLDivElement>(null);

	const formElementId = `hsForm_${formId}`;

	const formReady = (form: any) => {
		if (!form) {
			return;
		}

		const pathname = window.location.pathname.split("/");
		const store = pathname[1];
		const lang = pathname[2];

		// this updates the hrefs to go to the correct URL
		const anchors = form.getElementsByTagName("a");

		for (let i = 0; i < anchors.length; i++) {
			const updatedHref = `/${store}/${lang}${anchors[i].pathname}`;

			if (updatedHref !== anchors[i].href) {
				anchors[i].href = updatedHref;
			}
		}
	};

	useEffect(() => {
		setId(v4());
	}, []);

	useEffect(() => {
		if (!id) {
			return;
		}

		if (!region || !portalId || !formId) {
			return;
		}

		const newScript = document.createElement("script");
		newScript.setAttribute("async", "");
		newScript.id = formId;
		newScript.innerHTML = `
			hbspt.forms.create({
					region: "${region}",
					portalId: "${portalId}",
					formId: "${formId}",
					onFormReady: ${formReady}	
			});
		`;

		target?.current?.appendChild(newScript);

		return () => {
			document.getElementById(formElementId)?.remove();
		};
	}, [id]);

	return (
		<div className='hubspot-form' ref={target}>
			{isEditingBuilder && (!region || !portalId || !formId) && (
				<p>HubSpot Form: Missing data</p>
			)}
		</div>
	);
};

export default ScriptLoader;

Builder.registerComponent(ScriptLoader, {
	name: "HubSpotForm",
	inputs: [
		{
			name: "region",
			type: "longText",
			required: true,
			helperText: "HubSpot Account Region",
			defaultValue: "eu1",
		},
		{
			name: "portalId",
			friendlyName: "Portal ID",
			type: "longText",
			required: true,
			helperText: "HubSpot Account/Portal ID ",
			defaultValue: "000000000",
		},
		{
			name: "formId",
			friendlyName: "Form ID",
			type: "longText",
			required: true,
			defaultValue: "000000a0-aa0a-00aa-00a0-000a00a0AA00",
		},
	],
});

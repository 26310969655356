import { Builder } from "@builder.io/react";
import { FC } from "react";
import CompressedImage from "@/components/UI/CompressedImage/CompressedImage";
import styles from "./Image.module.scss";

interface ImageSliderProps {
	imageSrc: any;
	altText: string;
	lazyLoad: boolean;
}

const Image: FC<ImageSliderProps> = ({ imageSrc, altText, lazyLoad }) => {
	return (
		<section className={styles.imageContainer}>
			<CompressedImage
				src={imageSrc}
				height={500}
				width={900}
				altText={altText}
				loading={lazyLoad ? "lazy" : "eager"}
			/>
		</section>
	);
};

export default Image;

Builder.registerComponent(Image, {
	name: "Image Block",
	inputs: [
		{
			name: "imageSrc",
			friendlyName: "Image",
			type: "file",
			allowedFileTypes: ["jpeg", "jpg", "png"],
			defaultValue: "https://placehold.co/900x500",
			helperText: "900×500 px | .jpeg, .jpg, .png | ~125 KB",
		},
		{
			name: "altText",
			friendlyName: "Alt Text",
			type: "string",
			required: true,
			defaultValue: "This is alt text",
		},
		{
			name: "lazyLoad",
			friendlyName: "Lazy Load?",
			type: "boolean",
			defaultValue: "true",
		},
	],
});
